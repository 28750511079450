import React from "react"
import { Scatter } from "react-chartjs-2"
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js"
import { Box, useMediaQuery } from "@mui/material"

// Register necessary components for the scatter chart
ChartJS.register(Tooltip, Legend, PointElement, CategoryScale, LinearScale)

const ScatterPlotC = () => {
  const cities = [
    "Sacramento, CA",
    "Zephyrhills, FL",
    "Frisco, TX",
    "Little Rock, AR",
    "Norfolk, VA",
    "Brookfield, WI",
    "Olathe, KS",
    "Boulder, CO",
    "Richmond, KY",
    "Highland Park",
  ]

  // Data for the scatter plot
  const data = {
    datasets: [
      {
        label: "Population",
        data: [
          { x: 0, y: 500000 }, // Sacramento, CA
          { x: 1, y: 15575 }, // Zephyrhills, FL
          { x: 2, y: 210000 }, // Frisco, TX
          { x: 3, y: 197000 }, // Little Rock, AR
          { x: 4, y: 244000 }, // Norfolk, VA
          { x: 5, y: 38000 }, // Brookfield, WI
          { x: 6, y: 141000 }, // Olathe, KS
          { x: 7, y: 105000 }, // Boulder, CO
          { x: 8, y: 35000 }, // Richmond, KY
          { x: 9, y: 30163 }, // Highland Park
        ],
        backgroundColor: "rgba(255, 99, 132, .6)", // Red for Population
        borderColor: "rgba(255, 99, 132, 1)",
        pointRadius: (context) => (context.dataIndex === 9 ? 10 : 6), // Highland Park has larger radius
        pointStyle: (context) =>
          context.dataIndex === 9 ? "rectRot" : "circle", // Highland Park has a diamond shape
        yAxisID: "yPopulation", // Assign to Population Y-axis
      },
      {
        label: "Salary",
        data: [
          { x: 0, y: 493655 }, // Sacramento, CA
          { x: 1, y: 105000 }, // Zephyrhills, FL
          { x: 2, y: 333380 }, // Frisco, TX
          { x: 3, y: 192000 }, // Little Rock, AR
          { x: 4, y: 272000 }, // Norfolk, VA
          { x: 5, y: 180000 }, // Brookfield, WI
          { x: 6, y: 210000 }, // Olathe, KS
          { x: 7, y: 232000 }, // Boulder, CO
          { x: 8, y: 165000 }, // Richmond, KY
          { x: 9, y: 310000 }, // Highland Park
        ],
        backgroundColor: "rgba(88, 194, 86, .6)", // Green for Salary
        borderColor: "rgba(88, 194, 86, 1)",
        pointRadius: (context) => (context.dataIndex === 9 ? 12 : 8), // Highland Park has custom radius
        pointStyle: (context) =>
          context.dataIndex === 9 ? "rectRot" : "circle", // Highland Park has a diamond shape
        yAxisID: "ySalary", // Assign to Salary Y-axis
      },
    ],
  }

  // Detect screen size using MUI's `useMediaQuery`
  const isMobile = useMediaQuery("(max-width:600px)")

  // Options for the scatter plot
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yPopulation: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Population",
        },
        position: "left", // Left Y-axis for Population
        ticks: {
          callback: function (value) {
            // Abbreviate numbers to 'k' for thousands
            const suffixes = ["", "k", "M", "B", "T"]
            let tier = (Math.log10(Math.abs(value)) / 3) | 0
            if (tier === 0) return `${value}` // No abbreviation needed
            const suffix = suffixes[tier]
            const scale = Math.pow(10, tier * 3)
            const scaled = value / scale
            return `${scaled}${suffix}`
          },
        },
      },
      ySalary: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Salary",
        },
        position: "right", // Right Y-axis for Salary
        ticks: {
          callback: function (value) {
            // Abbreviate numbers to 'k' for thousands
            const suffixes = ["", "k", "M", "B", "T"]
            let tier = (Math.log10(Math.abs(value)) / 3) | 0
            if (tier === 0) return `$${value}` // No abbreviation needed
            const suffix = suffixes[tier]
            const scale = Math.pow(10, tier * 3)
            const scaled = value / scale
            return `$${scaled}${suffix}`
          },
        },
      },
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Cities",
        },
        ticks: {
          callback: function (value) {
            return cities[value]
          },
          // Apply different rotation based on screen size
          minRotation: isMobile ? 85 : 45,
          maxRotation: isMobile ? 85 : 45,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Manager Salary Vs Pop. Size",
        font: {
          size: 22,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const datasetLabel = context.dataset.label || ""
            const value = context.raw.y
            return `${datasetLabel}: $${value.toLocaleString()}`
          },
          title: function (context) {
            return cities[context[0].raw.x]
          },
        },
      },
    },
  }

  return (
    <Box sx={{ width: { xs: "100%", sm: "600px" }, height: "400px" }}>
      <Scatter data={data} options={options} />
    </Box>
  )
}

export default ScatterPlotC
