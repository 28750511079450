import React, { useRef, useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material"
import { Link } from "react-router-dom"
import planC from "../planC.json"
import ArrowBackIcon from "@mui/icons-material/ArrowBack" // Import back arrow icon
// import planC from "../planC.json"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import RateTaxPlanModal from "../components/RateTaxPlanModal"
import PieChartC from "../components/PieChartC"
import PieChartCc from "../components/PieChartCc"
import RevCompsB from "../components/RevCompsB"
import RevPercentCompC from "../components/RevPercentCompC"
import BarGraphC from "../components/BarGraphC"
import { Helmet } from "react-helmet"
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500"
// import OndemandVideoIcon from "@mui/icons-material/OndemandVideo"
// import YouTube from "react-youtube" // Import YouTube component
import ScatterPlotC from "../components/ScatterPlotC"

function createData(revenueStream, currentTax, newTax, amusement) {
  return { revenueStream, currentTax, newTax, amusement }
}

const rows = [
  createData("5% Admissions Fee (Example)", "$1,750,000", "$1,750,000", "N/A"),
  createData("Carbon Emissions Tax", "N/A", "$210,600", "$210,600"),
  createData("Naming Rights Tax", "N/A", "$350,000", "$350,000"),
  createData("12% Amusement Tax", "N/A", "N/A", "$4,200,000"),
  createData("Property Tax Equivalent", "N/A", "N/A", "$1,400,000"),
  createData("Total Annual Revenue", "$1,750,000", "$2,310,600", "$6,160,600"),
  createData("% of City's Budget", "1.4%", "1.95%", "5.22%"),
]

const headerCell = {
  fontWeight: "bold", // Bold text
  backgroundColor: "#f5f5f5", // Light grey background
}

const PlanC = () => {
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState("panel3") // Initially expand Section 4

  // const [showVideo, setShowVideo] = useState(false) // New state for showing video
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false) // Toggle accordion expansion
  }
  const tableRef = useRef(null)

  useEffect(() => {
    const isSmallScreen = window.innerWidth <= 600 // Define your mobile breakpoint
    if (isSmallScreen && tableRef.current) {
      const scrollAmount = tableRef.current.scrollWidth * 1 // Scroll to 100% of the table width
      tableRef.current.scrollLeft = scrollAmount
    }
  }, [])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const boldifyPercentage = (text) => {
    // Split the text on every "5%" and store it in an array
    const parts = text.split(
      "adding approximately 5% to the city’s annual budget."
    )

    return (
      <>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {/* Add bold "5%" after every part except the last */}
            {index < parts.length - 1 && (
              <strong>
                adding approximately 5% to the city’s annual budget.
              </strong>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }

  // Helper function to highlight specific text
  const highlightText = (text) => {
    // Define regex patterns for the strings to highlight
    const regex = /(Section 4:|Section 3:|Section 5:)/g

    // Split the text based on the regex
    const parts = text.split(regex)

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "#f0c829" }}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Helmet>
        <title>The RISE Plan</title>
      </Helmet>
      {/* Floating Back Button */}
      <Link to="/plan" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          sx={{
            position: "fixed",
            top: "16px",
            left: "16px",
            zIndex: 1000, // Ensure it floats above other content
          }}
        >
          Back
        </Button>
      </Link>
      <a href="./assets/RISE_plan.pdf" download={"RISE_plan"} target="_blank">
        <Button
          endIcon={<CurrencyExchangeIcon />}
          variant="contained"
          color="success"
        >
          Download the full Plan here
        </Button>
      </a>
      {/* Button to open the modal */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleOpen()
        }}
        startIcon={<StarBorderPurple500Icon />}
      >
        Rate the Plan
      </Button>
      {/* adding comments */}
      <RateTaxPlanModal
        open={open}
        plan={"planC"}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      {/* {!showVideo && (
        <Button
          variant="contained"
          color="warning"
          onClick={() => setShowVideo(true)}
          sx={{ width: { sm: "80%" }, fontWeight: 900 }}
          endIcon={<OndemandVideoIcon />}
        >
          Watch the Video Proposal
        </Button>
      )} */}

      {/* YouTube video iframe */}
      {/* {showVideo && (
        <Box width={{ xs: "100%", md: "60%" }}>
          <YouTube
            style={{ flexGrow: "1" }}
            videoId="RFQSbc3EW4s"
            opts={{
              width: "100%",
              playerVars: {
                autoplay: 1,
                cc_load_policy: 1,
              },
            }}
          />
        </Box>
      )} */}
      <Box
        sx={{
          backgroundColor: "#2f3fcc",
          borderRadius: "15px",
          padding: "12px",
        }}
      >
        <Typography
          textAlign={"center"}
          fontSize={"42px"}
          sx={{
            textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
            fontWeight: 900,
            textDecoration: "underline",
            paddingBottom: "20px",
          }}
          color={"white"}
        >
          RISE:
        </Typography>
        <Typography
          textAlign={"center"}
          fontSize={"24px"}
          sx={{
            textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
            fontWeight: 600,
          }}
          color={"white"}
        >
          Revenue Initiatives for Sustainable Equity
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: ".875rem",
        }}
      >
        <Box
          sx={{
            border: "2px black solid",
            borderRadius: "15px",
            padding: "1rem",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}
          >
            Objective:
          </Typography>
          <Typography>{boldifyPercentage(planC.objective)}</Typography>
          <Typography sx={{ marginTop: ".5rem" }}>
            {planC.objective2}
          </Typography>
        </Box>
        <Box
          sx={{
            marginRight: { xs: "-2rem", sm: "4rem" },
            marginLeft: { xs: "-2rem", sm: "4rem" },
          }}
        >
          {planC.sections.map((section, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",

                    color: "black",
                  }}
                >
                  {highlightText(section.title)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{section.description}</AccordionDetails>
              {section.description2 && (
                <AccordionDetails>{section.description2}</AccordionDetails>
              )}
              {section.description3 && (
                <AccordionDetails>{section.description3}</AccordionDetails>
              )}
              <AccordionDetails>
                {section.calculationDescription}
              </AccordionDetails>
              <AccordionDetails>
                {section.calculation}
                <span style={{ fontWeight: 900 }}>{section.totalRevenue}</span>
              </AccordionDetails>
              {section.calculationDescription2 && (
                <>
                  <AccordionDetails>
                    {section.calculationDescription2}
                  </AccordionDetails>
                  <AccordionDetails>
                    {section.calculation2}
                    <span style={{ fontWeight: 900 }}>
                      {section.totalRevenue2}
                    </span>
                  </AccordionDetails>
                </>
              )}
              {section.closingArg && (
                <AccordionDetails>
                  <Typography
                    fontWeight={600}
                  >{`***${section.closingArg}***`}</Typography>
                </AccordionDetails>
              )}
              {index === planC.sections.length - 3 && (
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <PieChartC /> {/* Render the Pie Chart */}
                  <PieChartCc />
                  <BarGraphC />
                </AccordionDetails>
              )}
              {index === planC.sections.length - 2 && (
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  <ScatterPlotC />
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </Box>
      </Box>
      <Box textAlign={"center"}>
        <Typography fontWeight={900} fontSize={24}>
          Side-by-Side Comparison of Revenue
        </Typography>
        <Typography fontSize={12}>
          **Using Ravinia Festival as ONLY an example**
        </Typography>
        <Typography fontSize={12}>
          **Based off an average of $35,000,000 in annual revenue as only an
          example**
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)", // Thicker shadow
          borderRadius: "12px", // Optional: To give it rounded corners
          // padding: "1rem", // Optional: Adds padding around the table
          overflowX: "auto",
          width: { xs: "100vw", sm: "80vw" },
        }}
        ref={tableRef}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ ...headerCell, position: "sticky", top: 0, left: 0 }}
              >
                Revenue Stream
              </TableCell>
              <TableCell sx={headerCell} align="right">
                Current (Before New Taxes)
              </TableCell>
              <TableCell sx={headerCell} align="right">
                New Taxes (Before Agreement Expiration)
              </TableCell>
              <TableCell sx={headerCell} align="right">
                Amusement Tax (After Agreement Expiration)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.revenueStream}
                sx={{
                  "&:nth-last-child(-n+2) td": {
                    fontWeight: 900, // bold text for the last two rows
                    fontSize: "18px", // 16px font size for the last two rows
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: index >= rows.length - 2 ? 900 : "inherit",
                    fontSize: index >= rows.length - 2 ? "18px" : "inherit",
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white", // Add a background color to avoid transparency
                    zIndex: 1, // Ensures the header is above the other cells
                    ...headerCell,
                    ...(index >= 0 &&
                      index < rows.length - 2 && {
                        fontSize: "14px",
                        fontWeight: 400,
                      }),
                  }}
                >
                  {row.revenueStream}
                </TableCell>
                <TableCell align="right">{row.currentTax}</TableCell>
                <TableCell align="right">{row.newTax}</TableCell>
                <TableCell align="right">{row.amusement}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RevCompsB />
      <RevPercentCompC />

      <Typography fontWeight={900} fontSize={24}>
        Conclusion
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          paddingX: { xs: "none", sm: "1.5rem" },
          marginRight: { xs: "-1rem", sm: "4rem" },
          marginLeft: { xs: "-1rem", sm: "4rem" },
          gap: "1rem",
        }}
      >
        <Typography>{planC.conclusion}</Typography>
        <Typography>{planC.conclusion2}</Typography>
        <Typography>{planC.conclusion3}</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<StarBorderPurple500Icon />}
      >
        Rate the Plan
      </Button>
      <a href="./assets/RISE_plan.pdf" download={"RISE_plan"} target="_blank">
        <Button
          endIcon={<CurrencyExchangeIcon />}
          variant="contained"
          color="success"
        >
          Download the Plan!
        </Button>
      </a>
    </Box>
  )
}

export default PlanC
